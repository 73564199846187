/**
 * Fill all null values from top to bottom for an argument
 * also coerce empty strings to null
 * @param {string[]} y        Partially empty Array
 * @param {int} defaultValue  Default value for first fill-in
 * @returns {number[]}        Fully filled in Array
 */
function fill_nulls(y, defaultValue = 0) {
  let memo = parseFloat(y[0]) || defaultValue;
  return y.map((el) => {
    const v = parseFloat(el);
    if (v === null || isNaN(v)) {
      return memo;
    } else {
      memo = v;
      return memo;
    }
  });
}

/**
 * Round to certain decimal only if value is not NaN
 * @param {number} val      Number to be rounded
 * @param {int} decimals    Number of decimals to round to
 * @returns {string}        Rounded number
 */
window.toFixedConditional = function(val, decimals) {
  if (isNaN(parseFloat(val))) {
    return val;
  } else {
    return (val)?.toFixed(decimals);
  }
};

/**
 * Search upwards in arr starting from index and return the first non-null value
 * @param {number[]} arr  Array of values
 * @param {int} index     Index from which to start looking
 * @returns {number}      Value of first non-null value up
 */
window.firstValueUp = function(arr, index) {
  for (let i = index; i >= 0; i--) {
    if (arr[i] !== null) {
      return arr[i];
    }
  }
  return 0;
};

/**
 * Search upwards in arr starting from index and return the first non-null value
 * @param {string} func     String describing how to fill given array
 * @param {number[]} arr    Array from which to get placeholder
 * @param {int} index       Index for which to get placeholder
 * @returns {number}        Calculated value for placeholder
 */
window.tablePlaceholder = function(func, arr, index) {
  if (func === 'linear interpolation') {
    return linear_interpolation_single(arr, index);
  } else if (func === 'first value up') {
    return window.firstValueUp(arr, index);
  }
  return window.firstValueUp(arr, index);
};

/**
 * Traverse an entire array, and fill all blank spots with linearly interpolated data
 * @param {number[]} y    Partially empty Array
 * @returns {number[]}    Filled Array
 */
function linear_interpolation(y) {
  return y.map((el, i) => linear_interpolation_single(y, i));
}

/**
 * Given an array and an index, determine the value there,
 * linearly interpolating between the nearest filled neighbours if null,
 * and keeping the value at the only neighbour if outside the bounds of filled values
 * @param {number[]} arr    Partially empty Array
 * @param {int} index       Index for which to infer value
 * @returns {number}        Inferred value
 */
function linear_interpolation_single(arr, index) {
  // If the value is present we don't need to do any work
  if (!(arr[index] === null)) {
    return arr[index];
  }

  // Search downwards for a lower bound
  let lower_bound = null;
  for (let i = index - 1; i >= 0; i--) {
    if (!(arr[i] === null)) {
      lower_bound = i;
      break;
    }
  }

  // Search upwards for an upper bound
  let upper_bound = null;
  for (let i = index + 1; i < arr.length; i++) {
    if (!(arr[i] === null)) {
      upper_bound = i;
      break;
    }
  }

  // If both a lower bound and an upper bound have been found, interpolate
  if (!(lower_bound === null) && !(upper_bound === null)) {
    const d = upper_bound - lower_bound;
    const w1 = (upper_bound - index) / d;
    const w2 = (index - lower_bound) / d;
    const w_avg = arr[lower_bound] * w1 + arr[upper_bound] * w2;
    return w_avg;
  } else if (!(lower_bound === null)) {
    return arr[lower_bound];
  } else if (!(upper_bound === null)) {
    return arr[upper_bound];
  } else {
    // this last case only occurs when absolutely no values have been filled. Assume 0.
    return 0;
  }
}

/**
 * Fill up array based on given method
 * @param {number[]} y      Partially empty Array
 * @param {boolean} linear     Whether or not to use linear interpolation
 * @returns {number[]}      Filled Array
 */
function interp(y, linear = false) {
  if (linear) {
    return linear_interpolation(y);
  } else {
    return fill_nulls(y);
  }
}

/**
 * Fill in the first input array with values from the second
 * input array as long as they are valid values
 * @param {number[]} y    base array
 * @param {number[]} z    array to paste over first one
 * @returns {number[]}    final array
 */
function paste_over(y, z = []) {
  // z ??= [];
  return y.map((el, i) => {
    if (i < z.length && (!!z[i] || z[i] === 0)) {
      return parseFloat(z[i]);
    } else {
      if (el == null || isNaN(el)) {
        console.error('First array needs to be fully filled in to prevent interpolation differences');
      }
      return el;
    }
  });
}

/**
 * get average value of array
 * @param {number[]} array
 * @returns {number}
 */
function avg(array) {
  const arr = fill_nulls(array);
  return arr.reduce((a, b) => a + b) / arr.length;
}

function sum_arrays(args, i) {
  return args.reduce((a, arg) => a + arg[i], 0);
}

// triangle(x, 7) is 1 at x=0 and 0 at |x|=7 and beyond
function triangle(x, width) {
  return Math.max(1 - Math.abs(x) / width, 0);
}

// trapezium(x, step, fwhm) is 0 at x=0 and 0 at fwhm+step and beyond, and 1 from step to fwhm
function trapezium(x, step, fwhm) { // eslint-disable-line no-unused-vars
  if (x < 0) {
    return 0;
  } else if (x > fwhm + step) {
    return 0;
  } else if (x < step) {
    return x / step;
  } else if (x > fwhm) {
    return 1 - (x - fwhm) / step;
  } else {
    return 1;
  }
}

/**
 * this function requires multiple lists of the same length and filters duplicate
 * combinations per index
 * example:
 * list 1: [1,1,2,2,3,3]
 * list 2: [1,1,2,2,3,4]
 * result: [1,2,3,3], [1,2,3,4]
 * @param  {Array.<number[]>} args   list of same-length lists
 * @returns {Array.<number[]>}       list of filtered lists
 */
function filter_duplicates(...args) {
  const zip = (lists) => lists[0].map((_, c) => lists.map((list) => list[c]));
  const compare_arr = (a, b) => a.every((v, i) => v === b[i]);
  const filter = (lists) => lists.reverse().filter(
      (v, i) => !lists.slice(i + 1).map((a) => compare_arr(v, a)).some(Boolean)
  ).reverse();
  if (args[0].length == 0) {
    args = [[null], [null]];
  }
  const result = zip(filter(zip(args)));
  return result;
}

/**
 * Calculate the cumulative sum of an array
 * @param {number[]} array    original array
 * @returns {number[]}        cumulative sum per index
 */
function cumsum(array) {
  return array.map(((sum) => (value) => sum += value)(0));
}

/**
 * Fill unfilled elements of given array with zeros
 * @param {number[]} array    Original array
 * @returns {number[]}        Filled array
 */
function null_to_zero(array) {
  return array.map((e) => e || 0);
}

/**
 * Fills part of array outside of start and end with nulls
 * @param {number[]} arr    original array
 * @param {int} start       starting index
 * @param {int} end         final index
 * @returns {number[]}      sliced array
 */
function mask_slice_arr(arr, start, end) {
  if (end == -1) {
    end = arr.length;
  }
  return arr.map((v, i) => {
    if (i >= start && i < end) {
      return v;
    } else {
      return null;
    }
  });
}

/**
 * Fills part of array outisde of start and end with nulls for every key in object
 * @param {Object} obj      Original object
 * @param {int} start       Starting index
 * @param {int} end         Final index
 * @returns {Object}        Object with sliced arrays
 */
function mask_slice_obj(obj, start, end) {
  const mask_slice_obj = {};
  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      mask_slice_obj[key] = mask_slice_arr(obj[key], start, end);
    }
  }
  return mask_slice_obj;
}

/**
 * add generic aggregate function if in the future other functions than sum are needed
 * @param {number[]} arr                  Array of values
 * @param {string} aggregation_function   Which aggregation function should be applied
 * @param {boolean} sum_to_week              Whether or not to sum over every day separately
 * @param {int} n_decimals                Number of decimals to round to
 * @param {boolean} fill_nulls               Whether or not to fill empty values
 * @param {<int|boolean>} current_week_idx   Index of current week in array if applicable
 * @returns {string}                      Aggregated string of sums in given array
 */
window.aggregateArray = function({
  arr, aggregation_function, sum_to_week = false, n_decimals = 0, fill_nulls = true, current_week_idx = false
}) {
  if (arr.length === 0) {
    return '0';
  }
  let aggregated_str = '';
  if (aggregation_function === 'sum') {
    let summed = window.sumValuesAbove(arr, arr.length - 1, fill_nulls);
    if (Number.isNaN(summed)) summed = 0;
    if (sum_to_week) summed *= 7;
    aggregated_str = summed?.toFixed(n_decimals);
  } else if (aggregation_function === 'sum/total_sum') {
    let summed = window.sumValuesAbove(arr, current_week_idx, fill_nulls);
    if (Number.isNaN(summed)) summed = 0;
    if (sum_to_week) summed *= 7;
    let summed_total = window.sumValuesAbove(arr, arr.length - 1, fill_nulls);
    if (Number.isNaN(summed_total)) summed_total = 0;
    if (sum_to_week) summed_total *= 7;
    aggregated_str = [summed?.toFixed(n_decimals), summed_total?.toFixed(n_decimals)].join('/');
  } else {
    console.error('Unknown aggregation function given', aggregation_function);
  }
  return aggregated_str;
};

function round(val, dec = 0) {
  return Math.round(val * Math.pow(10, dec)) / Math.pow(10, dec);
}

// Calculations for making graphs. These must accept an array
// indexed by week_number. Any nulls in inputs should be resolved

const Utils = {
  avg: avg,
  cumsum: cumsum,
  fill_nulls: fill_nulls,
  filter_duplicates: filter_duplicates,
  interp: interp,
  linear_interpolation_single,
  mask_slice_arr: mask_slice_arr,
  mask_slice_obj: mask_slice_obj,
  null_to_zero: null_to_zero,
  paste_over: paste_over,
  round: round,
  sum_arrays: sum_arrays,
  triangle: triangle
};

window.utils = Utils;
module.exports = Utils;
