function registration_needs_warning(crops) {
  return Object.values(crops).some((x) => x._status == 'has_changes');
}

const save_status_class_dict = {
  undefined: 'bg-white',
  new: 'bg-white',
  has_changes: 'bg-yellow-100',
  saving: 'bg-yellow-100',
  ready: 'bg-green-400',
  saved: 'bg-green-400',
  error: 'bg-red-400'
};

const save_status_sym_dict = {
  undefined: '∘︎',
  new: '∘︎',
  has_changes: '!',
  saving: '...',
  saved: '\u2713',
  error: 'x'
};

// alpine store to keep track of which crop is being registered
window.Alpine.store('selected_crop_id', '');

window.Alpine.magic('save_status_class', () => {
  return (status) => save_status_class_dict[status];
});

window.Alpine.magic('save_status_sym', () => {
  return (status) => save_status_sym_dict[status];
});

window.Alpine.magic('registration_needs_warning', () => {
  return (crops) => registration_needs_warning(crops);
});

function split_stem_name(stem_rows, name) {
  // extract the number part of the stem name
  const prefix = name.replace(/\D/g, '');

  // First find the last name matching this prefix
  const suffix = stem_rows.map((row) => row.name)
      .filter((name) => name.startsWith(prefix))
      .map((name) => name.replace(/\d/g, ''))
      .reduce((max, suffix) => {
        return suffix > max ? suffix : max;
      }, '`'); // the backtick is the character before 'a'

  const nextChar = String.fromCharCode(suffix.charCodeAt(0) + 1);
  return prefix + nextChar;
}

function new_stem_row(name) {
  const new_stem = {name,
    truss_rows: [],
    current:
        {custom_vars: {}}};
  return new_stem;
}

function new_truss_row(crop, stem_row) {
  const truss_index = stem_row.truss_rows.reduce((max, obj) => {
    return obj.truss_index > max ? obj.truss_index : max;
  }, 0) + 1;
  const expected_buds = crop['realized_row']['pruning_policy'] ||
    crop['realized_row']['previous']['pruning_policy'] ||
    (stem_row.truss_rows.length > 0 ? stem_row.truss_rows[stem_row.truss_rows.length - 1].expected_buds : 5);
  return {
    truss_index: truss_index,
    harvested: false,
    expected_buds: parseFloat(expected_buds),
    set_fruits: 0
  };
}

window.Alpine.magic('next_stem_name', () => {
  return (stem_rows) => {
    const maxNr = stem_rows.reduce((max, obj) => {
      return Number(obj.name) > max ? Number(obj.name) : max;
    }, 0);
    return String(maxNr + 1);
  };
});

window.Alpine.magic('split_stem', () => {
  return (stem_rows, name, index) => {
    const new_name = split_stem_name(stem_rows, name);
    // We want to insert this split stem just before the next number

    const first_larger_entry = stem_rows.map((row) => Number(row.name.replace(/\D/g, '')))
        .findIndex((nm) => String(nm) > new_name);
    if (first_larger_entry >= 0) {
      stem_rows.splice(first_larger_entry, 0, new_stem_row(new_name));
    } else {
      stem_rows.push(new_stem_row(new_name));
    }
    return new_name;
  };
});

window.Alpine.magic('new_stem_row', () => {
  return new_stem_row;
});

window.Alpine.magic('new_truss_row', () => {
  return new_truss_row;
});

window.Alpine.magic('recursive_set', () => {
  return recursiveSet;
});

function recursiveSet(data, name, value) {
  if (Array.isArray(data)) {
    // If it's an array, iterate through each element
    for (let i = 0; i < data.length; i++) {
      recursiveSet(data[i], name, value); // Recurse into the element
    }
  } else if (data !== null && typeof data === 'object') {
    // If it's an object, iterate through its keys
    for (const key in data) {
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty(key)) {
        if (key === name) {
          data[key] = value;
        } else {
          recursiveSet(data[key], name, value); // Recurse into the value
        }
      }
    }
  }
}
