/**
 * Given a string, detect a number in it and add delta to it, returning
 * the new string. If multiple numbers are found update the first one.
 * The number must be delimited with _
 * @param {string} string   id string of element
 * @param {int} delta       number by which to change the idx
 * @returns {string}        new id string of element to switch to
 */
function id_number_update(string, delta) {
  const parts = string.split('_');
  for (let i = 0; i < parts.length; i++) {
    const num = parseInt(parts[i], 10);
    if (!isNaN(num)) {
      parts[i] = num + delta;
      return parts.join('_');
    }
  }
  return string;
}

/**
 * Return the input field to the right, left, above and below the current input field
 * Try to move to a similar input field above or below the current one.
 * Do this by, starting with the grandparent element, looking for
 * inputs with a similar name, up to 4 trees away.
 * @param {Object} el                 starting element
 * @param {int} count                 number of neighbors to move
 * @param {function} idx_lambda       function that determines how to change idx
 * @param {string[][]} queries_list   list of lists of query strings
 * @param {bool} direct_nbrs          whether or not neighbor elements exist in same div
 * @returns {Object}                  target element to move to
 */
function moveBase(el, count, idx_lambda, queries_list, direct_nbrs) {
  for (let qi = 0; qi < queries_list.length; qi++) {
    let base = el;
    const queries = queries_list[qi];
    // look up 9 parent divs for neighboring field
    for (let i = 0; i < 9; i++) {
      let tr = base;
      // Move up or down depending on count
      for (let j = 0; j < count; j++) {
        tr = tr?.nextElementSibling;
      }
      for (let j = 0; j < -count; j++) {
        tr = tr?.previousElementSibling;
      }
      // Try to find an input (by id number match first, and otherwise just by input type)
      // TODO: should we enforce the layers here?

      // neighbours in same div
      if (direct_nbrs) {
        const element = (tr?.tagName == 'INPUT') ? tr : null;
        if (element) return element;
      }

      // neighbours in different div
      for (let i = 0; i < queries.length; i++) {
        const arr = tr?.querySelectorAll(queries[i] + ':not([type="hidden"])') || [];
        if (arr.length > 0) return idx_lambda(arr);
      }

      base = base.parentElement;
    }
  }
}

/**
 * Move elements vertically in table
 * @param {Object} el                 starting element
 * @param {int} count                 number of neighbors to move
 * @returns {Object}                  target element to move to
 */
function moveVertical(el, count) {
  const base = el;
  const idx_lambda = (arr) => arr[0];
  const queries_list = [['* > input#' + id_number_update(el.id, count)], ['* > input', '* > textarea']];
  const direct_nbrs = false;
  return moveBase(base, count, idx_lambda, queries_list, direct_nbrs);
}

/**
 * Move elements horizontally in table
 * @param {Object} el                 starting element
 * @param {int} count                 number of neighbors to move
 * @returns {Object}                  target element to move to
 */
function moveHorizontal(el, count) {
  const base = el;
  const idx_lambda = (count > 0) ? (arr) => arr[0] : (arr) => arr[arr.length - 1];
  const queries_list = [['input#' + el.id, '* > input', '* > textarea']];
  const direct_nbrs = true;
  return moveBase(base, count, idx_lambda, queries_list, direct_nbrs);
}

/**
 * Go to the next element if the cursor is at the last position
 * @param {Object} event    event that triggers table movement
 */
function next(event) {
  if (event.target.selectionStart === event.target.value.length) {
    moveHorizontal(event.target, 1)?.focus();
    event.preventDefault();
  }
}

/**
 * Go to the previous element if the cursor is at the first position
 * Since this event handler fires after the native cursor move
 * we don't know if the cursor was at 0 already or just arrived there.
 * therefore we move too often. A little annoying but not too bad
 * @param {Object} event    event that triggers table movement
 */
function prev(event) {
  if (event.target.selectionStart === 0) {
    moveHorizontal(event.target, -1)?.focus();
    event.preventDefault();
  }
}

/**
 * Go to the element above
 * Instead of this ID trickery we could also apply names to td's and match those perhaps
 * @param {Object} event    event that triggers table movement
 */
function up(event) {
  moveVertical(event.target, -1)?.focus();
  event.preventDefault();
}

/**
 * Go to the element below
 * @param {Object} event    event that triggers table movement
 */
function down(event) {
  moveVertical(event.target, 1)?.focus();
  event.preventDefault();
}

/**
 * Fix and normalize float dots and commas for different locales
 * @param {string} float_str  string representing float value
 * @returns {string}          normalized float string of form '1,000,000.0'
 */
function fix_decimals(float_str) {
  const first_comma = float_str.indexOf(',');
  const first_point = float_str.indexOf('.');
  if (first_comma > first_point) {
    float_str = float_str.replace('.', '');
    float_str = float_str.replace(',', '.');
  } else {
    float_str = float_str.replace(',', '');
  }
  return float_str;
}

/**
 * Given pasted data, split it into rows and columns and update the corresponding cells
 * @param {Object} event    Event triggering paste
 */
function paste(event) {
  const rootEl = event.target;

  // Split contents into rows and columns
  event.clipboardData.getData('text/plain').split('\n').forEach((row, i) => {
    if (row === '') {
      return;
    }
    // Get a pointer to the start of this row
    const rowPointer = moveVertical(rootEl, i);
    if (rowPointer === null || rowPointer === undefined) {
      console.log('Insufficient rows to paste data, truncating');
      return;
    }

    row.split('\t').forEach((column, j) => {
      // Get a pointer to this element
      const pointer = moveHorizontal(rowPointer, j);
      if (pointer === null || pointer === undefined) {
        console.log('Insufficient columns to paste data, truncating');
        return;
      }

      // from comma decimal separator to point decimal separator
      column = fix_decimals(column);
      // Fill the value in the current element
      pointer.value = column;
    });
  });
  event.preventDefault();
}

/**
 * Export plan values to csv format
 * @param {Object} plan_edit    Plan object with editable values
 * @param {Object} plan_calc    Plan object with calculated values
 * @param {Object} linear       Object describing which interpolation method should be used per attribute
 * @param {string[]} editables  List of editable variable names
 * @param {string[]} deriveds   List of derived variable names
 * @param {boolean} interp      Whether or not to interpolate
 * @param {number[]} weeks      Week numbers
 * @returns {string}            String representation of x-data for plan in csv format
 */
function export_plan(plan_edit, plan_calc, linear, editables, deriveds, interp, weeks, decimals_per_column) {
  // new objects to prevent overwriting
  const edits = Object.create(plan_edit);
  const calcs = Object.create(plan_calc);
  edits['week_number'] = weeks;
  // gather arrays of variables that should applied
  if (interp) {
    editables.map((key) => {
      Object.keys(plan_edit).includes(key) ? edits[key] = window.utils.interp(plan_edit[key], linear[key]) : '';
    });
  } else {
    editables.map((key) => {
      Object.keys(plan_edit).includes(key) ? edits[key] = plan_edit[key] : '';
    });
  }
  deriveds.map((key) => {
    Object.keys(plan_calc).includes(key) ? calcs[key] = plan_calc[key] : '';
  });

  // build string for clipboard
  const plan_length = Object.values(edits)[0].length;
  const var_names_string = Object.keys(edits).concat(Object.keys(calcs)).join('\t');
  const values_list = [];
  for (let i = 0; i < plan_length; i++) {
    const a = (
      Object.keys(edits).map((key) => {
        return edits[key][i]?.toFixedNumber(decimals_per_column[key] || 0);
      }).concat(Object.keys(calcs).map((key) => {
        return calcs[key][i]?.toFixedNumber(decimals_per_column[key]);
      }))
    ).join('\t');
    values_list.push(a);
  }
  const var_values_string = values_list.join('\n');
  const result = var_names_string + '\n' + var_values_string;
  return result;
}

const Table = {
  down: down,
  export_plan: export_plan,
  fix_decimals: fix_decimals,
  id_number_update: id_number_update,
  next: next,
  paste: paste,
  prev: prev,
  up: up
};

window.table = Table;
module.exports = Table;
